import con from '../../../connection';

const path = '/logout';

const logout = (url: string) => () => ({
  post: () => con.post<{
    header: {
      status: string,
      status_code: number,
      messages: string[],
    },
    body: {
      data: [],
    },
  }>(url + path, {}),
});

export default logout;