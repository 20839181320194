import con from '../../../../connection';

const path = '/auth';

const auth = (url: string) => () => {
  return {
    post: (param: { token: string }) =>
      con.post<{
        header: {
          status: string;
          status_code: number;
          messages: string[];
        };
        body: {
          data: {
            user_id: string;
          };
        };
      }>(url + path, param),
  };
};

export default auth;
