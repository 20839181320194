import con from '../../../../../connection';
import files from './files';

const path = '/requests';

const requests = (url: string) => (request_id?: string) => ({
  files: files(`${url}${path}/${request_id || ''}`),
  post: (param: {
    deliver_date?: string,
    member_ids: string[],
    title: string,
    comment: string,
  }) => con.post<{
    header: {
      status: string,
      status_code: number,
      messages: { message: string },
    },
    body: {
      data: { request_id: string },
    },
  }>(url + path, param),
});

export default requests;
