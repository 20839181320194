import con from '../../../connection';
import { ApiGetAdminUsersResponse } from './api-get-isp-users.response';
import { ApiGetAdminUserDetailResponse } from './api-get-isp-user-detail.response';
import activate from './activate/index';
import auth from './auth/index';

const path = '/users';

const users = (url: string) => (user_id?: string) => ({
  auth: auth(`${url}${path}`),
  activate: activate(`${url}${path}`),
  get: (param?: {
    is_retirement?: boolean;
    sort_by?: number;
    highlow?: number;
      limit?: number;
      page?: number;
  }) =>
    !user_id && param
      ? con.get<ApiGetAdminUsersResponse>(url + path, param)
      : con.get<ApiGetAdminUserDetailResponse>(`${url}${path}/${user_id}`, {}),
  post: (param: {
    family_name: string;
    name: string;
    family_name_kana: string;
    name_kana: string;
    email: string;
    pass: string;
  }) =>
    con.post<{
      header: {
        status: string;
        status_code: number;
        messages: string[];
      };
      body: {
        data: [];
      };
    }>(url + path + (user_id ? `/${user_id}` : ''), param),
});

export default users;
