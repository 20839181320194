import { Mode } from '../index';
import islogin from './islogin/index';
import login from './login/index';
import logout from './logout/index';
import password from './password/index';
import portals from './portals/index';
import companies from './companies/index';
import { organizations } from './organizations';
import { members } from './members';
import common from './common/index';
import icon from './icon/index';
import profiles from './profiles/index';
import logs from './logs';
import informations from './informations/index';
import { articles } from './articles';

type Param = {
  mode: Mode,
  host?: string,
};

const companyWeb = (param: Param) => {
  const {
    mode,
    host: inputHost,
  } = param;
  // - chose host -
  let host = '';
  switch (mode) {
    case 'local':
      host = 'https://m-loop-company-web.marietta.dev';
      break;
    case 'develop':
      host = '.';
      break;
    case 'stage':
      host = '.';
      break;
    case 'production':
      host = '.';
      break;
  }
  // - path -
  const path = '/api';
  // - url -
  const url = `${inputHost || host}${path}/${process.env.REACT_APP_API_VERSION ?? 'v1'}`;
  return ({
    common: common(url),
    companies: companies(url),
    islogin: islogin(url),
    login: login(url),
    logout: logout(url),
    logs: logs(url),
    members: members(url),
    organizations: organizations(url),
    informations: informations(url),
    password: password(url),
    portals: portals(url),
    icon: icon(url),
    profiles: profiles(url),
    articles: articles(url),
  });
};

export default companyWeb;
