import con from '../../../../connection';
const path = '/activate';

const activate = (url: string) => () => {
  return {
    post: (param: {
      token: string;
      user_id: string;
      email: string;
      family_name: string;
      name: string;
      family_name_kana: string;
      name_kana: string;
      password: string;
    }) =>
      con.post<{
        header: {
          status: string;
          status_code: number;
          messages: string[];
        };
        body: {
          data: {
            user_id: string;
            email: string;
            family_name: string;
            name: string;
            family_name_kana: string;
            name_kana: string;
            token: string;
          };
        };
      }>(url + path, param),
  };
};

export default activate;
