import { ApiGetCompanyInformationsResponse } from './api-get-company-informations.response';
import { ApiGetCompanyInformationsDetailResponse } from './api-get-company-informations-detail.response';
import con from '../../../connection';
import categories from './categories/index';
import levels from './levels/index';
import destinations from './destinations/index';
import templates from './templates/index';

const path = '/informations';

const informations = (url: string) => (info_id?: string) => ({
  categories: categories(url + path),
  levels: levels(url + path),
  destinations: destinations(url + path, info_id),
  templates: templates(url + path),
  get: <
    ResponseType extends
      | ApiGetCompanyInformationsResponse
      | ApiGetCompanyInformationsDetailResponse
  >(param?: {
    notification_start_date_from?: string;
    notification_end_date_to?: string;
    created_at?: string;
    word?: string[];
    category?: number;
    level?: number;
    sort_by?: number;
    highlow?: number;
    limit?: number;
    page?: number;
  }) =>
    !info_id
      ? con.get<ResponseType>(url + path, param || {})
      : con.get<ResponseType>(`${url}${path}/${info_id}`, {}),
  post: (param: {
    title: string;
    body?: string;
    body_text?: string;
    level: number;
    category: number;
    is_url?: number;
    url?: string;
    notification_start_date: string;
    notification_end_date: string;
    sender_name: string;
    sender_list: string;
  }) =>
    con.post<{
      header: {
        status: string;
        status_code: number;
        messages: string[];
      };
      body: {
        data: {
          info_id: string;
        };
      };
    }>(url + path + (info_id ? `/${info_id}` : ''), param),
  delete: () =>
    con.delete<{
      header: {
        status: string;
        status_code: number;
        messages: string[];
      };
      body: {
        data: [];
      };
    }>(`${url}${path}/${info_id}`, {}),
});

export default informations;
