import con from '../../../../connection';

const path = '/reset';

const reset = (url: string) => () => ({
  post: (param: {
    email: string,
    pass: string,
  }) =>
    con.post<{
      header: {
        status: string;
        status_code: number;
        messages: string[];
      };
      body: {
        data: {
          token: string;
        };
      };
    }>(url + path, param),
});

export default reset;
