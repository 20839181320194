import con from '../../../../../connection';

const path = '/reinvite';

const reinvite = (url: string) => () => {
  return {
    post: (param: {
      pass: string;
    }) =>
      con.post<{
      header: {
        status: string;
        status_code: number;
        messages: string[];
      };
      body: {
        data: [];
      };
    }>( url + path, param)
  }
}

export default reinvite;