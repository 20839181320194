import con from '../../../connection';

const path = '/accounts';

const accounts = (url: string) => (member_id: string) => ({
  post: (param: {
    company_code: string,
  }) => con.post(url + path + "/" + member_id, param),
});

export default accounts;
