import con from "../../../../../connection";
import { BaseResponseType } from "../../../../../types/base-types";

const path = '/organizations'

export type GetResponseMasterOrganizationsDataEntity = {
  /** 企業コード */
  company_code: string,
  /** 組織コード */
  organization_code: string,
  /** 企業名 */
  company_name: string,
  /** 組織名 */
  organization_name: string,
}

export const organizations = (url: string) => () => {
  const fullPath = `${url}${path}`
  return {
    get: () => con.get<BaseResponseType<GetResponseMasterOrganizationsDataEntity[]>>(fullPath, {}),
  };
};

export default organizations;
