import con from '../../../../connection';
import { ApiGetOrganizationsResponse } from './api-get-organizations.response';
import { ApiGetOrganizationDetailResponse } from './api-get-organization-detail.response';
import address from './address/index';
import { merger } from './merger';

const path = '/organizations';

export const organizations = (url: string) => (organization_code?: string) => {
  return {
    address: address(`${url}${path}/${organization_code}`),
    merger: merger(`${url}${path}/${organization_code}`),
    get: <
      ResponseType extends
        | ApiGetOrganizationsResponse
        | ApiGetOrganizationDetailResponse,
    >() =>
      organization_code
        ? con.get<ResponseType>(`${url}${path}/${organization_code}`, {})
        : con.get<ResponseType>(url + path, {}),
    post: (
      param:
        | {
            organization_name: string;
            is_not_address_copy: boolean;
            zip_code: string;
            pref_id: number;
            address: string;
            others: string;
            parent_organization_code: string;
            parent_hierarchy: number;
          }
        | {
            organization_name: string;
            is_not_address_copy: boolean;
            zip_code: string;
            pref_id: number;
            address: string;
            others: string;
          },
    ) =>
      con.post<{
        header: {
          status: string;
          status_code: number;
          messages: string[];
        };
        body: {
          data: {
            organization_code: string;
            organization_name: string;
            hierarchy: number;
            hierarchy_number: number;
            member_count: number;
          };
        };
      }>(
        url + path + (organization_code ? `/${organization_code}` : ''),
        param,
      ),
    delete: () => con.delete<{
      header: {
        status: string;
        status_code: number;
        messages: string[];
      },
      body: {
        data: []
      },
    }>(
      `${url}${path}/${organization_code}`, {}
    )
  };
};
