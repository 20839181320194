import reset from './reset/index';
import auth from './auth/index';
import set from './set/index';


const path = '/password';

const password = (url: string) => () => ({
  reset: reset(`${url}${path}`),
  auth: auth(`${url}${path}`),
  set: set(`${url}${path}`),
})

export default password;