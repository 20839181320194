import con from '../../../connection';
import plans from './plans';
import files from './files';

const path = '/articles';

export type TGetArticle = { name: string };
export type TGetArticleList = { name: string }[];

const articles = (url: string) => (article_id?: string) => ({
  plans: plans(`${url}${path}/${article_id || ''}`),
  files: files(`${url}${path}/${article_id || ''}`),
  get: <ResponseType extends TGetArticle | TGetArticleList>(param?: {
    words?: string,
    is_deleted?: boolean,
    sort_by?: number,
    highlow?: number,
    limit?: number
    offset?: number,
  }) => !article_id && param ? (
    // 物件一覧取得
    con.get<ResponseType>(url + path, param)
  ) : (
    // 物件詳細取得
    con.get<ResponseType>(`${url}${path}/${article_id}`, {})
  ),
  post: (param: {
    article_number: string,
    name: string,
    area: string,
    building_use: string,
    building_structure: string,
    memo: string,
    'members[]': string[],
    design_status: number,
    work_status: number,
  }) => article_id ? (
    // 物件編集
    con.post<{ hoge: string }>(`${url}${path}/${article_id}`, param || {})
  ) : (
    // 物件新規作成
    con.post<{ piyo: string }>(`${url}${path}`, param || {})
  ),
  // 物件削除(論理削除)
  delete: () => con.delete<{
    header: {
      status: string,
      status_code: number,
      messages: string[],
    },
    body: { data: [] }
  }>(`${url}${path}/${article_id}`, {}),
  // 物件復活
  restore: () => con.post<{
    header: {
      status: string,
      status_code: number,
      messages: string[],
    },
    body: { data: [] }
  }>(`${url}${path}/${article_id}/restore`, {}),
  /** 物件移動 */
  move: (param: {
    /** 移動先組織コード */
    organization_code: string,
  }) => con.post<{}>(`${url}${path}/${article_id}/move`, param),
  /** 物件内アプリ連携起動数取得 */
  applications: () => con.get<{}>(`${url}${path}/${article_id}/applications`, {}),
});

export default articles;

