import { ApiGetCompanyInformationsTemplatesResponse } from './api-get-company-informations-templates.response';
import { ApiGetCompanyInformationsTemplatesDetailResponse } from './api-get-company-informations-templates-detail.response';
import con from '../../../../connection';
import name from './name/index';

const path = '/templates';

const templates = (url: string) => (id?: string) => ({
  name: name(`${url}${path}/${id}`),
  get: <
    ResponseType extends
      | ApiGetCompanyInformationsTemplatesResponse
      | ApiGetCompanyInformationsTemplatesDetailResponse
  >() => 
    id
    ? con.get<ResponseType>(`${url}${path}/${id}`, {})
    : con.get<ResponseType>(url + path, {}),
  post: (param: {
    name: string;
    names?: string[];
    organization_codes?: string[];
    occupation_ids?: string[];
    member_created_at_from?: string;
    member_created_at_to?: string;
    roles?: [];
    is_cadect1?: number;
    is_cadect2?: number;
    is_cadect3?: number;
    is_cadect4?: number;
    building_use?: string;
  }) =>
    con.post<{
      header: {
        status: string;
        status_code: number;
        messages: string[];
      };
      body: {
        data: {
          id: string;
        };
      };
    }>(url + path + (id ? `/${id}` : ''), param),
  delete: () =>
    con.delete<{
      header: {
        status: string;
        status_code: number;
        messages: string[];
      };
      body: {
        data: [];
      };
    }>(`${url}${path}/${id}`, {}),
});

export default templates;
