import { ApiGetCompanyInformationsLevelsResponse } from './api-get-company-informations-levels.response';
import con from '../../../../connection';

const path = '/levels';

const levels = (url: string) => () => ({
  get: <
   ResponseType extends ApiGetCompanyInformationsLevelsResponse
  >(param?: {
    is_valid?: number;
  }) => con.get<ResponseType>(url + path, param || {}),
});

export default levels;