import con from "../../../../connection";
import destinations from './destinations/index';
import organizations from "./organizations";

const path = '/requests'

// /** 並び順 */
// sort_order: number,
// /** jsonファイル */
// File: File,


export const requests = (url: string) => () => {
  const fullPath = `${url}${path}`
  return {
    destinations: destinations(fullPath),
    organizations: organizations(fullPath),
  };
};

export default requests;
