import { OutputData } from "@editorjs/editorjs";

export const createPlaneText = (api: OutputData): string => {
  let str = '';
  api.blocks.forEach((v, i) => {
    let notAdd = false;
    if (v.data.text) {

      v.data.text.split('').forEach((v2: string) => {
        if (v2 === '<') {
          notAdd = true;
        } else if (!notAdd && v2) {
          str += v2;
        } else if (v2 === '>') {
          notAdd = false;
        }
      })
    }
  });
  return str;
}