import con from '../../../../../connection';

const path = '/upload';

const upload = (url: string) => () => ({
  post: (param: { File: File }) =>
    con.post<{
      header: {
        status: string;
        status_code: number;
        messages: string[];
      };
      body: {
        hit_count: number;
        data: {
          company_code: string;
          organization_code: string;
          member_id: string;
          email: string;
          display_name: string;
          company_name: string;
          organization_name: string;
        };
      };
    }>(url + path, param),
});

export default upload;
