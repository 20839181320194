import { Leaf, Organization } from '../models';

export class OrganizationManager {
  // グループコードの桁数
  private groupCodeDigit = 2;

  // 企業名の文字列長
  private companyNameLength = 3;

  /** organizationのchildrenプロパティを設定する */
  setChildrenProps = (organizations: Organization[]) => {
    // 参照を切ってコピーする
    const orgs = [...organizations].map((v) => ({ ...v }));
    /** childrenを取得する関数 */
    const findChildren = (organization: Organization) => {
      const isChild = new RegExp(`^${organization.organization_code}[0-9]{${this.groupCodeDigit}}$`);
      const children = orgs.reduce((childrenTemp, org) => {
        if (org.organization_code.match(isChild)) {
          childrenTemp.push(org.organization_code);
        }
        return childrenTemp;
      }, [] as string[]);
      return children;
    };
    /** childrenをソートする関数 */
    const sortChildren = (children: string[]) => {
      const childrenTemp = [...children].sort((firstEle, secondEle) => {
        const firstEleNum = Number(
          firstEle.substr(this.companyNameLength, firstEle.length - this.companyNameLength),
        );
        const secondEleNum = Number(
          secondEle.substr(this.companyNameLength, secondEle.length - this.companyNameLength),
        );
        return firstEleNum - secondEleNum;
      });
      return childrenTemp;
    };
    const setChildrenOrgs = orgs.map((v) => {
      const children = findChildren(v);
      const sortedChildren = sortChildren(children);
      return ({ ...v, children: sortedChildren });
    });
    return setChildrenOrgs;
  };

  /**
   * organizationsからleafListを作成する関数
   * @param organizations Organization[]
   * @returns Leaf[]
   */
  convertOrganizations2LeafList = (organizations: Organization[]): Leaf[] => {
    // 整列されたLeafを保持する配列
    const leafList: Leaf[] = [];
    // 引数に取った親のleafのchildrenをleafListに追加する関数
    const findChildAndAddToList = (parentLeaf: Leaf) => {
      const children = organizations
        .find((organization) => parentLeaf.id === organization.organization_code)
        ?.children || [];
      children.forEach((childId) => {
        const child = organizations
          .find((organization) => organization.organization_code === childId);
        if (child) {
          const childLeaf: Leaf = {
            id: child.organization_code,
            label: child.organization_name,
            level: parentLeaf.level + 1,
            index: leafList.length,
            parent: parentLeaf,
            children: [] as Leaf[],
            isActive: false,
          };
          leafList.push(childLeaf);
          parentLeaf.children.push(childLeaf)
          // 再帰処理
          findChildAndAddToList(childLeaf);
        }
      });
    };
    // topLevelのleafから順にleafListに追加する
    organizations.forEach((organization) => {
      if (organization.organization_code.length === this.companyNameLength) {
        const topLevelLeaf = {
          id: organization.organization_code,
          label: organization.organization_name,
          level: 0,
          index: leafList.length,
          parent: null,
          children: [],
          isActive: false,
        };
        leafList.push(topLevelLeaf);
        findChildAndAddToList(topLevelLeaf);
      }
    });
    return leafList;
  };
}
