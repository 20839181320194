import { ApiGetAddressResponse } from './api-get-address.response';
import con from '../../../../connection';

const path = '/address';

const address = (url: string) => () => {
  return {
    get: () => con.get<ApiGetAddressResponse>(url + path, {}),
  };
};

export default address;
