import con from '../../../../../connection';

const path = '/download';

const download = (url: string) => () => ({
  // 操作ログ 出力
  get: (param: {
    extract_date_start?: string,
    extract_date_end?: string,
    member_id?: string,
    is_cadect?: boolean,
    is_masis?: boolean;
    is_plan?: boolean;
    kind: boolean,

  }) => con.getFile(url + path, param),
});

export default download;
