import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export type LoginCompanyUserInfoSlicerState = {
  companyUserInfo: {
        company_code:	string,
        member_id:	string,
        email:	string,
        organization_code:	string,
        occupation_id: number,
        industry_id: number,
        display_name:	string,
        role: number,
        is_owner: boolean,
        is_information: boolean,
        is_log_admin: boolean,
        is_cadect1: boolean,
        is_cadect2: boolean,
        is_cadect3: boolean,
        is_cadect4: boolean,
        is_valid: boolean,
        created_at: string,
        updated_at: string,
        company_name:	string,
        organization_name:	string,
  },
  companyUserIcon: {
  Filedata: string,
  },
};

const initialState: LoginCompanyUserInfoSlicerState = {
  companyUserInfo: {
        company_code:	'',
        member_id:	'',
        email:	'',
        organization_code:	'',
        occupation_id: 0,
        industry_id: 0,
        display_name:	'',
        role: 0,
        is_owner: false,
        is_information: false,
        is_log_admin: false,
        is_cadect1: false,
        is_cadect2: false,
        is_cadect3: false,
        is_cadect4: false,
        is_valid: false,
        created_at: '',
        updated_at: '',
        company_name:	'',
        organization_name:	'',
  },
  companyUserIcon: {
    Filedata: ''
  },
};

export const loginCompanyUserInfoSlice = createSlice({
  name: 'login-company-user-info',
  initialState,
  reducers: {
    setCompanyUserDetail: (state, action: PayloadAction<{
      companyUserInfo: {
        company_code:	string,
        member_id:	string,
        email:	string,
        organization_code:	string,
        occupation_id: number,
        industry_id: number,
        display_name:	string,
        role: number,
        is_owner: boolean,
        is_information: boolean,
        is_log_admin: boolean,
        is_cadect1: boolean,
        is_cadect2: boolean,
        is_cadect3: boolean,
        is_cadect4: boolean,
        is_valid: boolean,
        created_at: string,
        updated_at: string,
        company_name:	string,
        organization_name:	string,
      },
    }>) => {
      state.companyUserInfo = {...action.payload.companyUserInfo};
    },
    setCompanyUserIcon: (state, action: PayloadAction<{
      companyUserIcon: {
        Filedata: string
      },
    }>) => {
      state.companyUserIcon = action.payload.companyUserIcon;
    },

  },
});

export const loginCompanyUserInfoAction = loginCompanyUserInfoSlice.actions;
export const loginCompanyUserInfoReducer = loginCompanyUserInfoSlice.reducer;
