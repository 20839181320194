import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export type CommonDataSliceState = {
  industryList: {
    id: number,
    name: string,
    is_valid: boolean,
  }[],
  occupationList: {
    id: number,
    name: string,
    is_valid: boolean,
  }[],
};

const initialState: CommonDataSliceState = {
  industryList: [],
  occupationList: [],
};

export const commonDataSlice = createSlice({
  name: 'common-data',
  initialState,
  reducers: {
    setIndustryList: (state, action: PayloadAction<{
      id: number;
      name: string;
      is_valid: boolean;
    }[]> ) => {
      state.industryList = action.payload;
    },
    setOccupationList: (state, action: PayloadAction<{
      id: number,
      name: string,
      is_valid: boolean,
    }[]> ) => {
      state.occupationList = action.payload;
    },
  },
});

export const commonDataAction = commonDataSlice.actions;
export const commonDataReducer = commonDataSlice.reducer;
