import { Input } from '../../ui/input/input';
import { Button } from '../../ui/button/button';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { push } from '@lagunovsky/redux-react-router';
import { RoutingPath } from '../../../routes/routing-path';
import { PageComponentDefaultProps } from '../../../models/page-component-default-props';
import { dialogAction } from '../../../slices/dialog-slice';
import { Header } from '../../ui/header/header';
import { SideBar } from '../../ui/sidebar/sidebar';
import { BreadcrumbList } from '../../ui/breadcrumb-list/breadcrumb-list';
import { breadcrumbParts } from '../../../models/breadcrumb-parts';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { apiAdmin, ApiManager } from '../../../managers/api-manager';
import { apiIspUsersAction } from '../../../slices/api-isp/api-admin-users-slice';
import { QueryParamFormatter } from '../../../utilities/query-param-formatter';
import { loginIspUserInfoAction } from '../../../slices/login-user-info-slicer';
import { ValidationFactory } from '../../../managers/validation/validation-factory';
import { CheckAllValid, Validation } from '../../../managers/validation/validation';
import { IspLogOperation } from '../../../models/logs/isp-log-operation';
import { ApiGetAdminUserDetailResponse } from '../../../api/api/admin-web/users/api-get-isp-user-detail.response';
import { useParams } from 'react-router-dom';
type UserData = {
  familyName: string,
  fastName: string,
  familyNameKana: string,
  fastNameKana: string,
  mail: string,
};

export type IspUserEditProps = {
  userId?: string,
} & PageComponentDefaultProps;

const defaultUserData: UserData = {
  familyName: '',
  fastName: '',
  familyNameKana: '',
  fastNameKana: '',
  mail: '',
};

// バリデーション用意
const validations: { [key: string]: Validation } = {
  length256: ValidationFactory('length256'),
  requireMailConfirm: ValidationFactory('default'),
  requireMail: ValidationFactory('default'),
  checkMailConfirm: ValidationFactory('default'),
};

export const AdminUserEdit = (props: IspUserEditProps) => {
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector((state) => state.loginIspUserInfo);
  const userId = useParams<{ userId: string }>().userId;
    const isUpdate = Boolean(userId);
  // -------------------- state --------------------
  const [userData, setUserData] = useState(defaultUserData);
  // 姓
  const [familyName, setFamilyName] = useState(userData.familyName);
  const handlerChangeFamilyName = useCallback((v: string) => {
    setFamilyName(v);
  }, []);
  // 名
  const [fastName, setFastName] = useState(userData.fastName);
  const handlerChangeFastName = useCallback((v: string) => {
    setFastName(v);
  }, []);
  // セイ
  const [familyNameKana, setFamilyNameKana] = useState(userData.familyNameKana);
  const handlerChangeFamilyNameKana = useCallback((v: string) => {
    setFamilyNameKana(v);
  }, []);
  // メイ
  const [fastNameKana, setFastNameKana] = useState(userData.fastNameKana);
  const handlerChangeFastNameKana = useCallback((v: string) => {
    setFastNameKana(v);
  }, []);
  // メールアドレス
  const [mail, setMail] = useState(userData.mail);
  const handlerChangeMail = useCallback((v: string) => {
    setMail(v.replace(/[^a-zA-Z0-9!-/:-@¥[-`{-~]*$/, ''));
  }, []);
  // メールアドレス再入力
  const [mailConfirm, setMailConfirm] = useState(userData.mail);
  const handlerChangeMailConfirm = useCallback((v: string) => {
    setMailConfirm(v.replace(/[^a-zA-Z0-9!-/:-@¥[-`{-~]*$/, ''));
  }, []);
  // 有効 (未退職者)
  const [valid, setValid] = useState(true);
  const handlerChangeValid = useCallback((v: boolean) => {
    setValid(!v);
  }, []);
  // ISP オーナー
  const [isIspOwner, setIsIspOwner] = useState(false);
  const handlerChangeIsIspOwner = useCallback((v: boolean) => {
    setIsIspOwner(!v);
  }, []);

  // バリデーションのトータルチェック
  // const isDisabled = CheckAllValid(validations);
  const [isDisabled, setIsDisabled] = useState(CheckAllValid(validations));
  const [validFlag, setValidFlag] = useState(false);
  const mailRef = useRef<HTMLInputElement>(null);
  const mailConfirmRef = useRef<HTMLInputElement>(null);
  // -------------------- イベント --------------------
  /** 更新API呼び出し */
  const ispUserUpdate = () => {
    dispatch(apiIspUsersAction.update({
        userId: userId!,
        param: {
          family_name: familyName,
          name: fastName,
          family_name_kana: familyNameKana,
          name_kana: fastNameKana,
          email: mail,
          is_mth_owner: isIspOwner,
          is_retirement: valid,
          pass: '',
        },
        callback: {
          success: () => {
            dispatch(dialogAction.pushMessage({
              title: '完了',
              message: ['更新しました'],
              buttons: [{
                label: 'OK',
                callback: () => {
                  dispatch(dialogAction.pop());
                  /** 自身の情報の場合もあるのでログイン情報を更新する */
                  if (userId === userInfo.user_id) {
                    (apiAdmin.users(userInfo.user_id).get() as Promise<ApiGetAdminUserDetailResponse>)
                      .then((v) => {
                        dispatch(loginIspUserInfoAction.setIspUserDetail({
                          userInfo: {
                            user_id: v.body.data.user_id,
                            email: v.body.data.email,
                            family_name: v.body.data.family_name,
                            name: v.body.data.name,
                            family_name_kana: v.body.data.family_name_kana,
                            name_kana: v.body.data.name_kana,
                            is_mth_owner: v.body.data.is_mth_owner,
                          },
                        }));
                      });
                  }
                  const query = QueryParamFormatter.queryParse();
                  const _query = QueryParamFormatter.queryCompose(query);
                  dispatch(push(RoutingPath.adminUserList + '?' + _query));
                },
              }],
            }));
          },
          failed: () => setIsDisabled(false),
        },
      },
    ));
  };
  /** OK押下 */
  const handlerClickOk = useCallback(() => {
    setIsDisabled(true);
    /** 退職チェック */
    if (isUpdate) {
      if (!valid) {
        dispatch(dialogAction.pushMessage({
          title: '確認',
          message: ['このユーザーを退職させてもよろしいですか'],
          buttons: [{
            label: 'キャンセル',
            callback: () => {
              dispatch(dialogAction.pop());
              setIsDisabled(false);
            },
          },
            {
              label: 'OK',
              callback: () => {
                dispatch(dialogAction.pop());
                ispUserUpdate();
              },
            }],
        }));
        return;
      }
      ;
      ispUserUpdate();
    } else {
      apiAdmin.users().post({
        family_name: familyName,
        name: fastName,
        family_name_kana: familyNameKana,
        name_kana: fastNameKana,
        email: mail,
        pass: '/#/users/add',
      }).then((res) => {
        dispatch(dialogAction.pushSendMail({
          message: [
            '追加したメールアドレスへ',
            '新規登録画面の案内が届きます',
          ],
          callback: () => {
            dispatch(push(RoutingPath.adminUserList));
          },
        }));
      })
        .catch((e) => {
          ApiManager.errorFunc(e);
          setIsDisabled(false);
        });
    }
  }, [
    familyName,
    fastName,
    familyNameKana,
    fastNameKana,
    mail,
    mailConfirm,
    valid,
    isIspOwner,
  ]);
  /** キャンセル押下 */
  const handlerClickCancel = useCallback(() => {
    IspLogOperation(isUpdate ? 'ispUserEditCancel' : 'ispUserAddCancel', () => {
      const query = QueryParamFormatter.queryParse();
      const _query = QueryParamFormatter.queryCompose(query);
      dispatch(push(RoutingPath.adminUserList + '?' + _query));
    });
  }, []);
  const breadcrumbList = isUpdate ? [
    breadcrumbParts.isp.home,
    breadcrumbParts.isp.ispUserList,
    breadcrumbParts.isp.ispUserUpdate,
  ] : [
    breadcrumbParts.isp.home,
    breadcrumbParts.isp.ispUserList,
    breadcrumbParts.isp.ispUserRegister,
  ];
  /** メールのバリデーションチェック */
  const checkValidMailInput = useCallback(() => {
    if (!mailRef.current ||
      !mailConfirmRef.current ||
      mailConfirmRef.current.value.length <= 0) {
      return;
    }
    if (mailConfirm) {
      mailConfirmRef.current.focus();
      mailConfirmRef.current.blur();
      mailRef.current.focus();
    }
  }, [mail]);
  // -------------------- effect --------------------
  /** メールバリデーション監視 */
  useEffect(() => {
    if (validFlag && !isUpdate) {
      checkValidMailInput();
    }
  }, [mail]);
  /** バリデーションチェックの自動化 */
  useEffect(() => {
    setIsDisabled(CheckAllValid(validations));
  }, [
    fastName,
    familyName,
    fastNameKana,
    familyNameKana,
    mail,
    mailConfirm,
  ]);

  /** 編集時のデータ取得 */
  useEffect(() => {
    let unmounted = false;
    if (!userInfo.user_id) {
      return;
    }
    // userData 取得処理
    if (isUpdate) {
      (apiAdmin.users(userId).get() as Promise<ApiGetAdminUserDetailResponse>)
        .then((v) => {
          if (!unmounted) {
            const {
              family_name,
              name,
              family_name_kana,
              name_kana,
              email,
              is_valid,
              is_mth_owner,
            } = v.body.data;
            setFamilyName(family_name);
            setFastName(name);
            setFamilyNameKana(family_name_kana);
            setFastNameKana(name_kana);
            setMail(email);
            setMailConfirm(email);
            setValid(is_valid);
            setIsIspOwner(is_mth_owner);
          }
        });
    }
    setValidFlag(true);
    return () => {
      unmounted = true;
    };
  }, [userId]);

  /** バリデーションの追加 */
  useEffect(() => {
    if (isUpdate) {
      validations['length30'] = ValidationFactory('length30');
      validations['length256'] = ValidationFactory('length256');
      validations['familyNameKana'] = ValidationFactory('kana');
      validations['fastNameKana'] = ValidationFactory('kana');
      validations['requireFamilyName'] = ValidationFactory('requireEdit');
      validations['requireFastName'] = ValidationFactory('requireEdit');
      validations['requireFamilyNameKana'] = ValidationFactory('requireEdit');
      validations['requireFastNameKana'] = ValidationFactory('requireEdit');
      delete validations.requireMail;
      delete validations.requireMailConfirm;
      delete validations.checkMailConfirm;
    } else {
      validations['length30'] = ValidationFactory('length30');
      validations['length256'] = ValidationFactory('length256');
      validations['familyNameKana'] = ValidationFactory('kana');
      validations['fastNameKana'] = ValidationFactory('kana');
      validations['requireFamilyName'] = ValidationFactory('require');
      validations['requireFastName'] = ValidationFactory('require');
      validations['requireFamilyNameKana'] = ValidationFactory('require');
      validations['requireFastNameKana'] = ValidationFactory('require');
      validations['requireMail'] = ValidationFactory('require');
      validations['requireMailConfirm'] = ValidationFactory('require');
      validations['checkMailConfirm'] = new Validation({
        test: (v: string) => v === mailRef.current?.value,
        errorMessages: ['メールアドレスが一致しません'],
      });
    }
    setIsDisabled(CheckAllValid(validations));
  }, [validFlag]);

  return (
    <div
      id="App"
      className="isp_user edit"
    >
      <SideBar currentPage={isUpdate ? 'isp-user-list' : 'isp-user-add'} />
      <div className="main_cnt">
        <Header />
        <div className="inner">
          <BreadcrumbList breadcrumbList={breadcrumbList} />
          <section>
            <header>
              <h2>{isUpdate ? 'M-LOOPユーザー更新' : 'M-LOOPユーザー登録'}</h2>
            </header>
            <div className="edit_wrap">
              <div className="edit_box">
                <div className="item_wrap">
                  <div className="item_head">
                    M-LOOPユーザー名
                    <span className="required">必須</span>
                  </div>
                  <div className="item_cnt flex">
                    <div style={{ marginRight: '30px' }}>
                      <label className="fix_size">姓</label>
                      <Input
                        value={familyName}
                        onChange={(e) => handlerChangeFamilyName(e.target.value)}
                        validations={[
                          validations.requireFamilyName,
                          validations.length30,
                        ]}
                      />
                    </div>
                    <div>
                      <label className="fix_size">名</label>
                      <Input
                        value={fastName}
                        onChange={(e) => handlerChangeFastName(e.target.value)}
                        validations={[
                          validations.requireFastName,
                          validations.length30,
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <div className="item_wrap">
                  <div className="item_head">
                    M-LOOPユーザー名（カナ）
                    <span className="required">必須</span>
                  </div>
                  <div className="item_cnt flex">
                    <div style={{ marginRight: '30px' }}>
                      <label className="fix_size">セイ</label>
                      <Input
                        value={familyNameKana}
                        onChange={(e) => handlerChangeFamilyNameKana(e.target.value)}
                        validations={[
                          validations.requireFamilyNameKana,
                          validations.length30,
                          validations.familyNameKana,
                        ]}
                      />
                    </div>
                    <div>
                      <label className="fix_size">メイ</label>
                      <Input
                        value={fastNameKana}
                        onChange={(e) => handlerChangeFastNameKana(e.target.value)}
                        validations={[
                          validations.requireFastNameKana,
                          validations.length30,
                          validations.fastNameKana,
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <hr />
                <div className="item_wrap">
                  <div className="item_head">
                    メールアドレス
                    {isUpdate ?
                      <></>
                      :
                      <span className="required">必須</span>
                    }
                  </div>
                  <div className="item_cnt">
                    {isUpdate ?
                      <label className="text_box">{mail}</label>
                      :
                      <Input
                        value={mail}
                        boxSize="large"
                        onChange={(e) => handlerChangeMail(e.target.value)}
                        validations={[
                          validations.requireMail,
                          validations.length256,
                        ]}
                        ref={mailRef}
                      />
                    }
                  </div>
                </div>
                {/*
            * ログインユーザーがオーナーの場合はオーナー設定、ユーザー更新の場合は退職が表示されます
            * オーナー設定は Number(userInfo.is_mth_owner) を !Number(userInfo.is_mth_owner) にしてもらえれば強制的に表示できます
            */}
                {isUpdate ? (
                  <>
                    <hr />
                    <div className="item_wrap">
                      <div className="item_head">
                        権限
                      </div>
                      <div className="item_cnt">
                        {(userInfo.is_mth_owner) ? (
                          <div className={`checkbox ${(userInfo.user_id === userId || !valid) && 'disabled'}`}>
                            <Input
                              checked={isIspOwner}
                              type="checkbox"
                              id="setting_owner"
                              className="checkbox_style"
                              onChange={() => handlerChangeIsIspOwner(isIspOwner)}
                            />
                            <label htmlFor="setting_owner">オーナー設定</label>
                          </div>
                        ) : (<></>)}
                        {isUpdate ? (
                          <div className={`checkbox ${(userId === userInfo.user_id || isIspOwner) && ' disabled'}`}>
                            <Input
                              checked={!valid}
                              type="checkbox"
                              id="retirement"
                              className="checkbox_style"
                              onChange={() => handlerChangeValid(valid)}
                            />
                            <label htmlFor="retirement">退職</label>
                          </div>
                        ) : (<></>)}
                      </div>
                    </div>
                  </>
                ) : (<>
                  <div className={`item_wrap`}>
                    <div className="item_head">
                      メールアドレス（再入力）
                      <span className="required">必須</span>
                    </div>
                    <div className="item_cnt">
                      <Input
                        boxSize="large"
                        value={mailConfirm}
                        onChange={(e) => handlerChangeMailConfirm(e.target.value)}
                        validations={[
                          validations.requireMailConfirm,
                          validations.length256,
                          validations.checkMailConfirm,
                        ]}
                        ref={mailConfirmRef}
                      />
                    </div>
                  </div>
                </>)}
              </div>
              <div className="btn_box align_center">
                <Button
                  size="large"
                  color="tertiary"
                  label="キャンセル"
                  onClick={handlerClickCancel}
                />
                <Button
                  size="large"
                  label="OK"
                  onClick={handlerClickOk}
                  disabled={isDisabled}
                />
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
