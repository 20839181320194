import { OptionsType } from '..';
import con from '../../../connection';

const path = '/login';

const login = (url: string) => () => ({
  post: (param: {
    email: string,
    password: string,
  }, noError: boolean = false) => con.post(url + path, param, noError),
});

export default login;
