import con from '../../../../connection';

const path = '/check';

export const check = (url: string) => () => ({
  post: (param: {
    company_code: string,
  }) => con.post<{
    header: {
      status: string,
      status_code: number,
      messages: string[],
    },
    body: {
      data: {
        is_double: number,
      },
    },
  }>(url + path, param),
});
