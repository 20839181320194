import con from '../../../../connection';
import files from './files';
import requests from './requests';

const path = '/plans';

const plans = (url: string) => (plan_id?: string) => ({
  files: files(`${url}${path}/${plan_id || ''}`),
  requests: requests(`${url}${path}/${plan_id || ''}`),
  get: (param?: {
    is_deleted?: boolean,
    sort_by?: number,
    highlow?: number,
    limit?: number
    offset?: number,
  }) => plan_id ? (
    // プラン詳細取得
    con.get(`${url}${path}/${plan_id}`, {})
  ) : (
    // プラン一覧取得
    con.get(`${url}${path}`, param || {})
  ),
  post: (param: {
    plan_number?: string,
    name: string,
    work_status: number,
    memo?: string,
  }) => plan_id ? (
    // プラン更新
    con.post(`${url}${path}/${plan_id}`, param)
  ) : (
    // プラン新規作成
    con.post(`${url}${path}`, param)
  ),
  // プラン削除
  delete: () => con.delete<{
    header: {
      status: string,
      status_code: number,
      messages: string[],
    },
  }>(`${url}${path}/${plan_id}`, {}),
  // プラン復活
  restore: () => con.post<{
    header: {
      status: string,
      status_code: number,
      messages: string[],
    },
  }>(`${url}${path}/${plan_id}/restore`, {}),
  // プランコピー
  copy: (param: {
    /** プラン名　最大40文字 */
    name: string,
    /** メモ 最大 500 文字 */
    memo: string,
    /**
     * 依頼先も含めるかどうか
     *
     * 0: 含めない, 1: 含める
     */
    is_requests: number,
    /**
     * プラン管理ファイルも含めるかどうか
     *
     * 0: 含めない, 1: 含める
     */
    is_plan_files: number,
  }, noError: boolean, timeoutMS: number) => con.post<{
    header: {
      status: string,
      status_code: number,
      messages: string[],
    },
    body: {
      data: {
        plan_id: string,
      }
    }
  }>(`${url}${path}/${plan_id}/copy`, param, noError, timeoutMS),
});

export default plans;
