import con from "../../../../../connection";

export type GetResponseMasterAuthoritiesDetailDataEntity = {
  /** ID */
  id: number,
  /** "権限種別 1:共通 2:機能別" */
  kind: number,
  /** システム名 */
  system: string,
  /** 説明 */
  description: string,
  /** ファイル名 */
  filename: string,
  /** バージョン */
  version: string,
  /** 権限更新日 */
  update_date: string,
  /** 権限定義 */
  definition: string,
  /** 表示順 */
  sort_order: number,
  /** 登録日時 */
  created_at: string,
  /** 更新日時 */
  updated_at: string,
}

type PostRequestEditMasterAuthority<T> = {
  /** 種別 １：共通 ２：機能別 */
  kind: 1 | 2,
  /** 入力タイプ １：直接入力 ２：フォーム入力 */
  type: 1 | 2,
} & T

export type PostEditFormMasterAuth = PostRequestEditMasterAuthority<{
  sort_order: number,
  File: File,
}>

// /** 並び順 */
// sort_order: number,
// /** jsonファイル */
// File: File,

export type PostEditDirectMasterAuth = PostRequestEditMasterAuthority<{
  definition: string,
}>

export const authoritiesDetail = (url: string) => (id: number) => {
  return {
    get: () => con.get<{}>(`${url}/${id}`, {}),
    editDirect: (request: PostEditDirectMasterAuth) => con.post<{}>(`${url}/${id}`, request),
    editForm: (request: PostEditFormMasterAuth) => con.post<{}>(`${url}/${id}`, request),
  };
};

export default authoritiesDetail;
