import React from "react";
import { Input } from "../input/input";
import './checkbox.scss';

type CheckboxProps = {
  label: string,
  onClick: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void,
  id: string,
  disabled?: boolean,
  extraLabel?: {
    label: string,
    color: string,
  }
  className?: string,
  checked?: boolean,
};

export const Checkbox = (props: CheckboxProps) => {
  const { extraLabel, label, onClick, id, className, disabled, checked } = props;
  return (
    <div className={`responsive_checkbox${className ? ` ${className}` : ''}${disabled ? ' disabled' : ''}${!!extraLabel ? ' has_extra_label' : ''}`}>
      <label htmlFor={id} className="checked" style={{ display: 'flex' }}>
        <Input
          type="checkbox"
          id={id}
          onClick={onClick}
          disabled={disabled}
          checked={checked}
          hidden
        />
        <span className="responsive_checkbox__check" />
        {label !== '' && <span>{label}</span>}
      </label>
      {!!extraLabel && <span style={{ color: extraLabel.color }}>{extraLabel.label}</span>}
    </div>
  );
};