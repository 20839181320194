import {industries} from './industries';
import {occupations} from './occupations';

const path = '/common';

const common = (url: string) => () => ({
  industries: industries(url + path),
  occupations: occupations(url + path),
  post: () => ({}),
});

export default common;
