import { OptionsType } from '..';
import con from '../../../connection';
import members from './members';
import comments from './comments';
import files from './files';

const path = '/contacts';

const contacts = (url: string) => (contact_id?: string) => ({
  get: contact_id
    ? (options?: OptionsType) => con.get(url + path + "/" + contact_id, {}, options?.noError, options?.noNetworkError)
    : (
        param: {
          /**
           * 並替基準列
           *
           * 1.件名
           * 2.作成者
           * 3.最終更新日(デフォルト)
           */
          sort_by: number,
          /**
           * 並替方法
           *
           * 1.昇順
           * 2.降順
           */
          highlow: number,
          /**
           * 1 ページの行数
           *
           * 指定なしの場合はデフォルト 30 件
           */
          limit: number,
          /**
           * ページ数
           *
           * 指定なしの場合はデフォルト 1
           */
          page: number,
        },
        options?: OptionsType,
      ) => con.get(url + path, param, options?.noError, options?.noNetworkError),
  post: (
    param: {
      /** 件名 */
      title: string,
      /** 内容 */
      body: string,
      /** 連絡通知メンバのメンバ id */
      members?: any,
      /** アップロードファイル id */
      fileids?: any,
    },
    options?: OptionsType,
  ) => con.post<{
    header: {
      status: string,
      status_code: number,
      messages: string[],
    },
    body: {
      contact_id: string,
    }
  }>(url + path, param, options?.noError),
  files: files(url + path),
  comments: comments(url + path + '/' + contact_id),
  members: members(url + path + '/' + contact_id),
});

export default contacts;
