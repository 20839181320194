import con from "../../../connection";

const path = '/mfa/auth';

const mfa = (url: string) => () => {
  return {
    post: (param: {
      token: string,
    }) =>
      con.post<{
        header: {
          status: string;
          status_code: number;
          messages: string[];
        };
        body: {
          data: [];
        };
      }>(url + path, param),
  };
};

export default mfa;
