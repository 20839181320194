import { OptionsType } from '..';
import con from '../../../connection';

const path = '/informations';

type InformationsGetPramType = {
  /**
   * 並替基準列
   *
   * 1.開封
   * 2.通知レベル
   * 3.受信日(デフォルト)
   * 4.カテゴリ
   * 5.タイトル
   * 6.差出人
   */
   sort_by: number,
   /**
    * 並替方法
    * 0.照準
    * 1.降順(デフォルト)
    */
   highlow: number,
   /**
    * 1ページの行数
    *
    * 指定無しの場合はデフォルト 30 件
    */
   limit: number,
   /**
    * オフセット位置
    * 
    * 指定なしの場合はデフォルト 0
    * ページに対して 0 相対。
    * 1 ページ目の場合は 0
    * 3 ページ目の場合は 2 を設定する
    */
   offset: number,
};

const informations = (url: string) => () => ({
  get: (
    pram: InformationsGetPramType,
    option?: OptionsType
  ) => con.get(
    url + path,
    pram,
    option?.noError,
    option?.noNetworkError,
  ),
  getDetail: (information_id: string, options?: OptionsType) => con.get(
    url + path + '/' + information_id,
    {},
    options?.noError,
    options?.noNetworkError,
  ),
  post: (
    information_id: string,
    options?: OptionsType,
  ) => con.post(
    url + path + '/' + information_id,
    {},
    options?.noError,
    options?.timeoutMS,
  ),
});

export default informations;
