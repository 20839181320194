
/**
 * date -> str
 * @param {Number | string} number
 * @param {Number} totalDigit
 */
export const ZeroPadding = (number: number | string, totalDigit: number): string => {
  if (!number) {
    window.console.log('number is null : ', number);
    return '';
  }
  if (String(number).length > totalDigit) {
    // window.console.log('number is longer than total digit : ', number);
    return '';
  }
  return (`0000000000${String(number)}`).slice(-totalDigit);
};
