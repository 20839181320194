import con from '../../../../connection';

const path = '/occupations';

const occupations = (url: string) => () => ({
  // 職種一覧取得
  get: () => con.get(url + path, {}),
});

export default occupations;
