import con from '../../../../connection';
import { ApiGetCompaniesPortalsResponse } from './api-get-companies-portals.response';

const path = '/portals';

const portals = (url: string) => () => ({
  get: () => con.get<ApiGetCompaniesPortalsResponse>(url + path, {}),
});

export default portals;
