import auth from './auth/index';
import activate from './activate/index';

const path = '/users';

const users = (url: string) => () => ({
  auth: auth(`${url}${path}`),
  activate: activate(`${url}${path}`),
})

export default users;