import {createSlice} from '@reduxjs/toolkit';
import {apiCompany, ApiManager} from '../../managers/api-manager';
import {AppThunk} from '../../app/store';
import {loginCompanyUserInfoAction} from '../login-company-user-info-slicer';

export type CompanyLoginSlicerState = {};

const initialState: CompanyLoginSlicerState = {};

export const apiCompanyLoginSlice = createSlice({
  name: 'api-company-login',
  initialState,
  reducers: {},
});

const asyncAction = {
  login: (data: {
    param: {
      email: string,
      password: string,
      isKeep: boolean,
    },
    callback: {
      success: () => void,
      failed: () => void,
    },
  }): AppThunk => async (dispatch) => {
    try {
      const res = await apiCompany.login().post({
        email: data.param.email,
        password: data.param.password,
        is_keep : data.param.isKeep,
      });
        await dispatch(loginCompanyUserInfoAction.setCompanyUserDetail({
          companyUserInfo: res.body.data,
        }));
        await apiCompany.icon().get()
          .then((v) => {
            dispatch(loginCompanyUserInfoAction.setCompanyUserIcon({
              companyUserIcon: {
                Filedata: v.body.data.Filedata,
              },
            }));
          });
        await data.callback.success();
    } catch (e) {
      ApiManager.errorFunc(e);
      data.callback.failed();
    }
  },
};

export const apiCompanyLoginAction = Object.assign(apiCompanyLoginSlice.actions, asyncAction);
export const apiCompanyLoginReducer = apiCompanyLoginSlice.reducer;
