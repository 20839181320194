import con from "../../../../../connection";

const path = '/authorities';

const authorities = (url: string) => () => {
  return {
    get: () => con.get(`${url}${path}`, {}),
    getDetail: (member_id: string) => con.get(`${url}/${member_id}${path}`, {}),
  }
};

export default authorities;