import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionType } from '../models/action-type';

export type EnvState = {
  version: string,
  build: number,
};

const initialState: EnvState = {
  version: '',
  build: 0,
};

const get: ActionType<EnvState> = (state: EnvState) => {
  fetch('./env.json')
    .then((res) => res.json())
    .then((json) => {
      state.version = json.version;
      state.build = json.build;
    });
};


export const envSlice = createSlice({
  name: 'env',
  initialState,
  reducers: {
    get,
    set: (
      state,
      action: PayloadAction<{ version: string, build: number }>
    ) => ({
      ...state,
      version: action.payload.version,
      build: action.payload.build,
    }),
  },
});

export const envAction = envSlice.actions;
export const envReducer = envSlice.reducer;
