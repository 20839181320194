import con from '../../../../../connection';

const path = '/name';

const name = (url: string) => () => ({
  post: (param: { name: string }) =>
    con.post<{
      header: {
        status: string;
        status_code: number;
        messages: string[];
      };
      body: {
        data: {
          id: string;
        };
      };
    }>(url + path, param),
});

export default name;
