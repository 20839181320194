import con from '../../../connection';

const path = '/members';

const members = (url: string) => () => ({
  get: (param?: {
    /**
     * 組織コード
     * 
     * ?organization_code[0]=hgoe&organization_code[1]=piyo みたいにする
     */
    organization_code?: string,
    /** 検索ワード */
    word?: string,
  }) => con.get(`${url}${path}`, param || {}),
});

export default members;