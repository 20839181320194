import con from '../../../../connection';

const path = '/password';

const password = (url: string) =>() => ({
  post: (param: {
    password: string,
    new_password: string,
  }) => con.post<{
    header: {
      status: string,
      status_code: number,
      messages: string[],
    },
    body: {
      data: [],
    },
  }>(url + path, param),
});

export default password;