import { CompanyUserData } from '../models/company-user-data';
import { ActionType } from '../models/action-type';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type CompanyUserState = {
  companyUserList: CompanyUserData[];
  companyUserData: {
    member_id: string;
    company_code: string;
    company_name: string;
    organization_name: string;
    kind: boolean;
    token: string,
    email: string,
    familyName: string;
    fastName: string;
    familyNameKana: string;
    fastNameKana: string;
    password: string;
    _password: string;
};
};
export const companyUserDefaultData = {
  member_id: '',
  company_code: '',
  company_name: '',
  organization_name: '',
  kind: false,
  token: '',
  email: '',
  familyName: '',
  fastName: '',
  familyNameKana: '',
  fastNameKana: '',
  password: '',
  _password: '',
};
const initialState: CompanyUserState = {
  companyUserList: [],
  companyUserData: companyUserDefaultData,
};

// const get: ActionType<CompanyUserState> = (state) => {
//   const dummy: CompanyUserData[] = [
//       {
//         organization: '株式会社マリエッタ - 東京本社',
//         business: '一般土木建築業',
//         position: '事業部長',
//         name: '佐々木 一太郎',
//         email: 'sasaki@system.co.jp',
//         authority: '管理',
//         status: '',
//       },
//       {
//         organization: '株式会社マリエッタ - 九州営業所 - 佐賀営業所',
//         business: '一般土木建築業',
//         position: '係長',
//         name: '中島 慶次',
//         email: 'nakajima@system.co.jp',
//         authority: '',
//         status: '招待中',
//       },
//       {
//         organization: '株式会社マリエッタ - 九州営業所 - 佐賀営業所',
//         business: '一般土木建築業',
//         position: '主任',
//         name: '大田 和広',
//         email: 'kazuhiro@system.co.jp',
//         authority: 'ゲスト',
//         status: '',
//       },
//     ];
//     dummy.map(elm => (
//       state.companyUserList.push(elm)
//     ))
//   }

const pop: ActionType<CompanyUserState> = (state) => {
  state.companyUserList.splice(0, state.companyUserList.length);
};

export const companyUserSlice = createSlice({
  name: `companyUser`,
  initialState,
  reducers: {
    // get,
    pop,
    setList: (state, action: PayloadAction<CompanyUserData[]>) => {
      state.companyUserList = action.payload;
    },
    setData: (
      state,
      action: PayloadAction<{
        member_id: string;
        company_code: string;
        company_name: string;
        organization_name: string;
        kind: boolean;
        token: string,
        email: string,
        familyName: string;
        fastName: string;
        familyNameKana: string;
        fastNameKana: string;
        password: string;
        _password: string;
      }>,
    ) => {
      state.companyUserData = action.payload;
    },
  },
});

export const companyUserAction = companyUserSlice.actions;
export const companyUserReducer = companyUserSlice.reducer;
