import { CompanyData } from '../models/company-data';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type CompanyParams = {
  company_code: string,
  company_name: string,
  company_name_kana: string,
  industry_id: number,
  occupation_id: number,
  zip_code: string,
  pref_id: number,
  address: string,
  others: string,
  tel: string,
  owner_id: string,
  storage_size: number,
  member_use_count: number,
  info_use_count: number,
  log_use_count: number,
  cadect1_use_count: number,
  cadect2_use_count: number,
  cadect3_use_count: number,
  cadect4_use_count: number,
  scheduled_to_stop_at: string,
  is_stoped: boolean,
  created_at: string,
  updated_at: string,
  family_name: string,
  name: string,
  family_name_kana: string,
  name_kana: string,
  email: string,
  user_cadect1_use_count: number;
  user_cadect2_use_count: number;
  user_cadect3_use_count: number;
  user_cadect4_use_count: number;
  user_info_use_count: number;
  user_log_use_count: number;
  is_mfa: boolean,
};

export type CompanyState = {
  companyList: CompanyData[],
  companyData: CompanyParams,
  breadcrumbName: string
}

export const companyDefaultData = {
  company_code: '',
  company_name: '',
  company_name_kana: '',
  industry_id: 0,
  occupation_id: 0,
  zip_code: '',
  pref_id: 13,
  address: '',
  others: '',
  tel: '',
  owner_id: '',
  storage_size: 5,
  member_use_count: 1,
  info_use_count: 1,
  log_use_count: 1,
  cadect1_use_count: 1,
  cadect2_use_count: 1,
  cadect3_use_count: 1,
  cadect4_use_count: 1,
  scheduled_to_stop_at: '',
  is_stoped: false,
  created_at: '',
  updated_at: '',
  family_name: '',
  name: '',
  family_name_kana: '',
  name_kana: '',
  email: '',
  user_cadect1_use_count: 1,
  user_cadect2_use_count: 1,
  user_cadect3_use_count: 1,
  user_cadect4_use_count: 1,
  user_info_use_count: 1,
  user_log_use_count: 1,
  is_mfa: false,
};

const initialState: CompanyState = {
  companyList: [],
  companyData: companyDefaultData,
  breadcrumbName: ''
}

export const companySlice = createSlice({
  name: `company`,
  initialState,
  reducers: {
    setCompanyList: (state, action: PayloadAction<{
      companyList: CompanyData[],
    }>) => {
      state.companyList = action.payload.companyList
    },
    setBreadcrumbName: (state, action: PayloadAction<{ breadcrumbName: string }>) => {
      state.breadcrumbName = action.payload.breadcrumbName;
    },
    clearCompanyDetail: (state) => {
      state.companyData = { ...companyDefaultData };
    },
    // setCompanyDetail: (state, action: PayloadAction<{}>)
  },
});

export const companyAction = companySlice.actions;
export const companyReducer = companySlice.reducer;
