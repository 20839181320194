import { Leaf, Organization } from '../models';

export class OrganizationManager {
  // グループコードの桁数
  private groupCodeDigit = 2;

  // 企業名の文字列長
  private companyNameLength = 3;

  /** organizationのchildrenプロパティを設定する */
  setChildrenProps = (organizations: Organization[]) => {
    // 参照を切ってコピーする
    const orgs = [...organizations].map((v) => ({ ...v }));
    /** childrenを取得する関数 */
    const findChildren = (organization: Organization) => {
      const isChild = new RegExp(`^${organization.organization_code}[0-9]{${this.groupCodeDigit}}$`);
      const children = orgs.reduce((childrenTemp, org) => {
        if (org.organization_code.match(isChild)) {
          childrenTemp.push(org.organization_code);
        }
        return childrenTemp;
      }, [] as string[]);
      return children;
    };
    /** childrenをソートする関数 */
    const sortChildren = (children: string[]) => {
      const childrenTemp = [...children].sort((firstEle, secondEle) => {
        const firstEleNum = Number(
          firstEle.substr(this.companyNameLength, firstEle.length - this.companyNameLength),
        );
        const secondEleNum = Number(
          secondEle.substr(this.companyNameLength, secondEle.length - this.companyNameLength),
        );
        return firstEleNum - secondEleNum;
      });
      return childrenTemp;
    };
    const setChildrenOrgs = orgs.map((v) => {
      const children = findChildren(v);
      const sortedChildren = sortChildren(children);
      return ({ ...v, children: sortedChildren });
    });
    return setChildrenOrgs;
  };

  /**
   * organizationsからleafListを作成する関数
   * @param organizations Organization[]
   * @returns Leaf[]
   */
  convertOrganizations2LeafList = (organizations: Organization[]): Leaf[] => {
    // 整列されたLeafを保持する配列
    const leafList: Leaf[] = [];
    // 引数に取った親のleafのchildrenをleafListに追加する関数
    const findChildAndAddToList = (parentLeaf: Leaf) => {
      const children = organizations
        .find((organization) => parentLeaf.id === organization.organization_code)
        ?.children || [];
      children.forEach((childId) => {
        const child = organizations
          .find((organization) => organization.organization_code === childId);
        if (child) {
          const childLeaf: Leaf = {
            id: child.organization_code,
            label: child.organization_name,
            level: parentLeaf.level + 1,
            index: leafList.length,
            parent: parentLeaf,
            children: child.children  || [],
            memberCount: child.member_count
          };
          leafList.push(childLeaf);
          // 再帰処理
          findChildAndAddToList(childLeaf);
        }
      });
    };
    // topLevelのleafから順にleafListに追加する
    organizations.forEach((organization) => {
      if (organization.organization_code.length === this.companyNameLength) {
        const topLevelLeaf = {
          id: organization.organization_code,
          label: organization.organization_name,
          level: 0,
          index: leafList.length,
          parent: null,
          children: organization.children || [],
          memberCount: organization.member_count
        };
        leafList.push(topLevelLeaf);
        findChildAndAddToList(topLevelLeaf);
      }
    });
    return leafList;
  };
  /**
   * leafの末端のchildの最後尾のchildのindexを取得
   */
  findLastChildIndexFunc = (list: Leaf[], currentLeaf: Leaf) => {
    let index = currentLeaf.index;
    const findLastChildIndex = (data: Leaf) => {
      const lastChild = list.find((leaf) => data.children[data.children.length - 1] === leaf.id);
      if (!lastChild) {
        return;
      }
      index = lastChild.index;
      findLastChildIndex(lastChild);
    };
    findLastChildIndex(currentLeaf);
    return index;
  };
  /**
   * 登録ボタン表示用のleafを作成
   */
  addEntryButtonLeaf = (list: Leaf[]) => {
    const addEntryButtonLeafList = list.reduce((acc: Leaf[], current: Leaf) => {
      const latestCurrent = acc.find((leaf) => leaf.id === current.id);
      if (!latestCurrent) {
        return acc;
      }
      const lastChildIndex = this.findLastChildIndexFunc(acc, latestCurrent);
      const currentIndex = acc.findIndex((leaf) => leaf.id === current.id);
      if (currentIndex !== -1) {
        acc.splice(currentIndex, 1, { ...current, children: [...current.children, `${current.id}-entry`] });
      }
      acc.splice(lastChildIndex + 1, 0, { id: `${current.id}-entry`, label: '登録', level: current.level + 1, index: 0, parent: current, children: [], memberCount: current.memberCount});
      const result =  acc.map((v, i) => ({...v, index: i}));
      // console.log('acc', JSON.parse(JSON.stringify(acc)))
        return result;
    }, list);
    // parentのindexのズレを修正する
    const redoingAssignIndexList = addEntryButtonLeafList.map((leaf) => {
      const parent = addEntryButtonLeafList.find((v) => leaf.parent?.id === v.id);
      if (parent) {
        return { ...leaf, parent };
      }
      return leaf;
    });
    return redoingAssignIndexList;
  }
}
