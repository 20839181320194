import { OptionsType } from '../../';
import con from '../../../../connection';

const path = '/comments';

const comments = (url: string) => () => ({
  get: (options?: OptionsType) => con.get(url + path, {}, options?.noError, options?.noNetworkError),
  post: (
    param: {
      /** コメント内容 */
      body?: string,
      /**
       * アップロードファイル ID リスト
       * fileids[0]: file, fileids[1]: file ...
       */
      fileids?: any,
    },
    option?: OptionsType,
  ) => con.post(url + path, param, option?.noError, option?.timeoutMS),
});

export default comments;
