import con from '../../../connection';

const path = '/login';

const login = (url: string) => () => ({
  post: (param: {
    email: string,
    password: string,
    is_keep: boolean,
  }) => con.post<{
    header: {
      status: string,
      status_code: number,
      messages: string[],
    },
    body: {
      data: {
        company_code:	string,
        member_id:	string,
        email:	string,
        organization_code:	string,
        occupation_id: number,
        industry_id: number,
        display_name:	string,
        role: number,
        is_owner: boolean,
        is_information: boolean,
        is_log_admin: boolean,
        is_cadect1: boolean,
        is_cadect2: boolean,
        is_cadect3: boolean,
        is_cadect4: boolean,
        is_valid: boolean,
        created_at: string,
        updated_at: string,
        company_name:	string,
        organization_name:	string,
      },
    },
  }>(url + path, param),
});

export default login;
