import con from '../../../connection';

const path = '/exclusive';

const exclusive = (url: string) => () => ({
  delete: (email: string) => con.delete<{
    header: {
      status: string,
      status_code: number,
      messages: string[],
    },
  }>(url + path + '/' + email, {}),
});

export default exclusive;
