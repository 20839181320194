export const prefectureList = [
  {key: '01', label: '北海道'},
  {key: '02', label: '青森県'},
  {key: '03', label: '岩手県'},
  {key: '04', label: '宮城県'},
  {key: '05', label: '秋田県'},
  {key: '06', label: '山形県'},
  {key: '07', label: '福島県'},
  {key: '08', label: '茨城県'},
  {key: '09', label: '栃木県'},
  {key: '10', label: '群馬県'},
  {key: '11', label: '埼玉県'},
  {key: '12', label: '千葉県'},
  {key: '13', label: '東京都'},
  {key: '14', label: '神奈川県'},
  {key: '15', label: '新潟県'},
  {key: '16', label: '富山県'},
  {key: '17', label: '石川県'},
  {key: '18', label: '福井県'},
  {key: '19', label: '山梨県'},
  {key: '20', label: '長野県'},
  {key: '21', label: '岐阜県'},
  {key: '22', label: '静岡県'},
  {key: '23', label: '愛知県'},
  {key: '24', label: '三重県'},
  {key: '25', label: '滋賀県'},
  {key: '26', label: '京都府'},
  {key: '27', label: '大阪府'},
  {key: '28', label: '兵庫県'},
  {key: '29', label: '奈良県'},
  {key: '30', label: '和歌山県'},
  {key: '31', label: '鳥取県'},
  {key: '32', label: '島根県'},
  {key: '33', label: '岡山県'},
  {key: '34', label: '広島県'},
  {key: '35', label: '山口県'},
  {key: '36', label: '徳島県'},
  {key: '37', label: '香川県'},
  {key: '38', label: '愛媛県'},
  {key: '39', label: '高知県'},
  {key: '40', label: '福岡県'},
  {key: '41', label: '佐賀県'},
  {key: '42', label: '長崎県'},
  {key: '43', label: '熊本県'},
  {key: '44', label: '大分県'},
  {key: '45', label: '宮崎県'},
  {key: '46', label: '鹿児島県'},
  {key: '47', label: '沖縄県'},
];