
type Props = {};

export const Skeleton = (props: Props) => {
  return (
    <div className="skeleton">
      {/*Skeleton !!*/}
    </div>
  );
};
