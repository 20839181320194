import con from '../../../../connection';
import { ApiGetCompanyMembersResponse } from './api-get-company-members.response';
import { ApiGetCompanyMemberDetailResponse } from './api-get-company-member-detail.response';
import reinvite from './reinvite';
import authorities from './authorities/index';

const path = '/members';

export const members = (url: string) => (member_id?: string) => {
  return {
    reinvite: reinvite(`${url}${path}/${member_id}`),
    get: (param?: {
      is_retirement?: boolean;
      sort_by?: number;
      highlow?: number;
      limit?: number;
      page?: number;
    }) =>
      !member_id && param
        ? con.get<ApiGetCompanyMembersResponse>(url + path, param)
        : con.get<ApiGetCompanyMemberDetailResponse>(
            `${url}${path}/${member_id}`,
            {},
          ),
    authorities: authorities(`${url}${path}`),
    post: (param: {
      organization_code: string;
      email: string;
      industry_id: number;
      occupation_id: number;
      role: number;
      is_information: boolean;
      is_log_admin: boolean;
      is_cadect1: boolean;
      is_cadect2: boolean;
      is_cadect3: boolean;
      is_cadect4: boolean;
      pass: string;
      authorities: string,
    }) =>
      con.post<{
        header: {
          status: string;
          status_code: number;
          messages: string[];
        };
        body: {
          data: [];
        };
      }>(url + path + (member_id ? `/${member_id}` : ''), param),
      delete: () => {
        if (!member_id) throw new Error('member_id is not found !!');
        return con.delete(url + path + '/' + member_id, {});
      },  
  };
};
